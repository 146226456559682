<div [formGroup]="form" class="g2g-dropdown-container">
    @if(type == 'basic') {
    <p-dropdown [styleClass]="classes" [options]="options" [formControlName]="formcontrolname"
        [placeholder]="placeholder" [optionLabel]="displayLabel" [showClear]="showClear" [optionValue]="value" />
    @if(hasError(formcontrolname,'required')) {
    <small class="p-error d-flex mx-1">{{placeholder}} is required</small>
    }
    } @else {
    <p-dropdown styleClass="w-100" [options]="options" [formControlName]="formcontrolname" [optionLabel]="displayLabel"
        [optionValue]="value" [filter]="filter" [filterBy]="filterBy" [showClear]="showClear"
        [placeholder]="placeholder">
        <ng-template pTemplate="selectedItem" let-selectedOption>
            <div class="flex align-items-center gap-2">
                <div>{{ selectedOption[displayLabel] }}</div>
            </div>
        </ng-template>
        <ng-template let-option pTemplate="item">
            <div class="flex align-items-center gap-2">
                <div>{{ option[displayLabel] }}</div>
            </div>
        </ng-template>
    </p-dropdown>
    @if(hasError(formcontrolname,'required')) {
    <small class="p-error d-flex mx-1">{{placeholder}} is required</small>
    }
    }
</div>